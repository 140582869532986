import { Icon, Tags, Refs, RefProps } from "./MilestoneComponents"

export interface MilestoneProps {
  name: string
  description: string
  released: Date

  tags: string[]
  where: string
  refs?: RefProps[]
}

export default function Milestone(props: MilestoneProps) {
  return (
    <li className="mb-6 ml-6 pl-4">
      <span className="flex absolute w-7 h-7 -left-[.9rem]
        justify-center items-center bg-yellow-200 rounded-full
        ring-8 ring-yellow-100"
      >
        <Icon where={props.where} />
      </span>
      <h3 className="flex items-center mb-1
        text-lg font-semibold text-gray-900"
      >
        {props.name}
      </h3>
      <time className="block mb-2 leading-none
        text-sm font-normal  text-gray-400"
      >
        Released on {
          props.released.toLocaleDateString("en-VN", {
            year: 'numeric', month: 'short'
          })
        }
      </time>
      <p className="text-base mb-1 font-normal text-gray-500 md:max-w-2xl">
        {props.description}
      </p>
      <Refs refs={props.refs} />
      <Tags tags={props.tags} />
    </li>
  )
}
