import './App.scss';

import {
  Header,
  Skills
} from './Sections';

import {
  Background,
  Badges,
  Section,
  Timeline,
} from './Components';

const Frameworks = [
  { img: "im/fw/React.svg", value: "React", isWork: true },
  { img: "im/fw/jQuery.svg", value: "jQuery" },
  { img: "im/fw/VueJS.svg", value: "VueJS" },
  { img: "im/fw/ExpressJS.png", value: "ExpressJS" },
  { img: "im/fw/Bootstrap.svg", value: "Bootstrap" },
  { img: "im/fw/Tailwind.svg", value: "Tailwind" },
]

const Operations = [
  { img: "im/op/nginx.svg", value: "nginx", isWork: true },
  { img: "im/op/Moby.webp", value: "Docker", isWork: true },
  { img: "im/op/Gitlab.svg", value: "Gitlab", isWork: true },
  { img: "im/op/Centos.svg", value: "Centos", isWork: true },
  { img: "im/op/Memcached.png", value: "Memcached", isWork: true },
  { img: "im/op/Ubuntu.png", value: "Ubuntu" },
  { img: "im/op/Prometheus.svg", value: "Prometheus" },
  { img: "im/op/Grafana.svg", value: "Grafana" },
]

const Languages = [
  { img: "im/lg/C++.svg", value: "C/C++", isWork: true },
  { img: "im/lg/Perl.png", value: "Perl", isWork: true },
  { img: "im/lg/JavaScript.png", value: "JavaScript", isWork: true },
  { img: "im/lg/TypeScript.svg", value: "TypeScript", isWork: true },
  { img: "im/lg/Java.svg", value: "Java", isWork: true },
  { img: "im/lg/Python.svg", value: "Python" },
  { img: "im/lg/Go.svg", value: "Go" },
  { img: "im/lg/PHP.svg", value: "PHP" },
  { img: "im/lg/CSharp.svg", value: "CSharp" },
]

const Milestones = [
  // {
  //   released: new Date(2022, 10),
  //   name: "Meida Storage \\@ Zalo",
  //   description: "Design & Develop database which storing ZMedia (Video, File, ...) can serve over TB a day.",
  //   tags: ["C/C++", "libevent"],
  //   where: "work",
  // },
  {
    released: new Date(2021, 7),
    name: "University Schedule",
    description: "Simple web app helps me and my friends chose cousres quickly before \"Course Offering\" and when the courses we want to offer are out of slots (UI/UX of this webpage is not good because I think it's an \"internal\" tool)",
    tags: ["Vue", "Boostrap", "jQuery"],
    where: "school",
    refs: [
      {
        title: "Github",
        link: "https://github.com/nhalq/UITScheduler"
      },
      {
        title: "Webpage",
        link: "https://nhalq.github.io/UITScheduler/Search.html"
      }
    ]
  },
  {
    released: new Date(2019, 11),
    name: "Ticket Booking \\@ The Connection Day",
    description: "Book Tickets Page for \"Lê Quý Đôn - The Connection Day\" - a team building event.",
    tags: ["PHP", "Bootstrap", "MySQL"],
    where: "lab",
  },
  {
    name: "Road to Cadu \\@ Math1619",
    released: new Date(2018, 3),
    description: "Inspired by \"The Best of Pre-Olympians\". I created a software to play \"Đường lên đỉnh Olympia\" - a TV Program on VTV3 - for my class at the time of \"sinh hoạt lớp\" - a session (once a week) which Vietnamese students review the activities of the previous week and play together.",
    tags: ["C#", "Windows Forms .NET", "MSSQL"],
    where: "lab",
  }
]

function App() {
  return (
    <>
      <Background />
      <div className="container pt-4 md:mx-auto divide-y-2 divide-dashed">
        <Header />
        <Section
          header="Projects"
          content={<Timeline milestones={Milestones} />}
        />
        <Section header="Skills" content={<Skills />} />
        <Section header="Operations" content={<Badges bages={Operations} />} />
        <Section header="Frameworks" content={<Badges bages={Frameworks} />} />
        <Section header="Languages" content={<Badges bages={Languages} />} />
      </div>
    </>
  )
}

export default App;
