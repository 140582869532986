import Milestone, { MilestoneProps } from "./Milestone"

interface TimelineProps {
  milestones: MilestoneProps[]
}

export default function Timeline(props: TimelineProps) {
  return (
    <ol className="relative border-l border-gray-200">
      {
        props.milestones.map(milestone => (
          <Milestone key={milestone.name} {...milestone} />)
        )
      }
      <li className="ml-6">
        <span className="flex absolute w-2 h-2 -left-[.3rem]
          justify-center items-center bg-yellow-200 rounded-full
          ring-8 ring-yellow-100"
        ></span>
        <div className="flex"></div>
      </li>
    </ol>
  )
}
