interface TypoProps {
  children: string
}

export default function Typo(props: TypoProps) {
  return (
    <h1 className="bg-gradient-to-r from-grd-a to-grd-a
        bg-clip-text text-transparent text-3xl rotate-3 italic
        p-5 transition-all duration-250 ease-linear
        hover:scale-150 hover:rotate-0 hover:to-grd-b"
      style={{ fontFamily: "'Pacifico', cursive" }}>
      {props.children}
    </h1>
  )
}
