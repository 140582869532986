import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

interface ListItemProps {
  children: string | JSX.Element
}

function ListItem(props: ListItemProps) {
  return (
    <li className="flex items-center mt-2 text-lg">
      <KeyboardDoubleArrowRightIcon className="mr-2 text-yellow-400" />
      {props.children}
    </li>
  )
}

interface ListProps {
  items: (string | JSX.Element)[]
}


export default function List(props: ListProps) {
  return (
    <ul>
      {
        props.items?.map((child, index) => (
          <ListItem key={index} children={child} />
        ))
      }
    </ul>
  )
}
