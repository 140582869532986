import Typo from "./Typo"

interface SectionProps {
  header: string
  content?: JSX.Element | JSX.Element[]
}

export default function Section(props: SectionProps) {
  return (
    <div className="mt-4 p-5 md:pt-5 pt-0">
      <div className="grid md:grid-cols-4 grid-cols-1">
        <div className="flex justify-center items-center min-h-[96px]">
          <Typo>{props.header}</Typo>
        </div>
        <div className="col-span-3 m-3">
          {props.content}
        </div>
      </div>
    </div>
  )
}
