import { useCallback, useState } from "react";

import Modal from "./Modal";
import ModalContext, {
  ModalCallback,
  ModalCallbackProps,
} from "./ModalContext";

interface ModalProviderProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function ModalProvider({ children }: ModalProviderProps) {
  const [isShow, setShow] = useState(false)
  const [props, setProps] = useState<ModalCallbackProps>({
    children: null,
    onClose: () => { }
  })

  const modal = useCallback<ModalCallback>((props: ModalCallbackProps) => {
    setShow(true)
    setProps(props)
  }, [])

  function onClose() {
    setShow(false)
  }

  return (
    <>
      <ModalContext.Provider value={modal}>
        {children}
      </ModalContext.Provider>
      <Modal open={isShow} children={props.children} onClose={onClose} />
    </>
  )
}
