import { Fragment } from 'react';

import CloseIcon from '@mui/icons-material/Close';

interface ModalProps {
  open: boolean
  children: null | JSX.Element | JSX.Element[]

  onClose: () => void
}

export default function Modal({ open, children, onClose }: ModalProps) {
  return (
    <Fragment>
      <div
        className="fixed h-full w-full z-50 bg-gray-600
          transition-all duration-500"
        style={{
          top: "0",
          opacity: open ? "80%" : "0%",
          visibility: open ? "visible" : "hidden",
        }}
      ></div>
      <div
        className="fixed top-0 z-50 w-full h-full
          transition-all duration-500 ease-inear"
        style={{ left: open ? "0%" : "100%" }}
      >
        <div className="h-1/5 w-full" onClick={onClose}></div>
        <div className="p-5 h-3/5 bg-gray-100 z-2">
          <button
            className="btn absolute hover:rotate-180 transition-all"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
          {children}
        </div>
        <div className="h-1/5 w-full" onClick={onClose}></div>
      </div>
    </Fragment>
  )
}
