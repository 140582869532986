import { useContext } from "react"
import ModalContext from "./ModalContext"
import ModalProvider from "./ModalProvider"

const useModal = () => useContext(ModalContext)

export {
  useModal,
  ModalProvider,
}
