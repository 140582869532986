import Badge, { BadgeProps } from "./Badge";

interface BadgesProps {
  bages: BadgeProps[]
}

export default function Badges(props: BadgesProps) {
  return (
    <div className="grid xl:grid-cols-6 grid-cols-3 gap-6">
      {props.bages.map(badge => <Badge key={badge.value} {...badge} />)}
    </div>
  )
}
