import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CallIcon from '@mui/icons-material/Call';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Header() {
  return (
    <div className="flex md:flex-row flex-col justify-center items-center">
      <img className={"w-32 h-32 p-1 rounded-full bg-gradient-to-r from-grd-a to-grd-b"}
        src="images/avatar.jpg" alt="" />
      <div className="flex flex-col items-center md:ml-10">
        <h1 className="text-6xl font-bold p-3 bg-clip-text text-transparent bg-gradient-to-r from-grd-a to-grd-b"
          style={{ fontFamily: "'Dancing Script', cursive" }}>
          Lê Quang Nha
        </h1>
        <div className="mt-2">
          <a className="ml-7 mr-7" href="mailto:nhalq287@gmail.com">
            <AlternateEmailIcon />
          </a>
          <a className="ml-7 mr-7" href="tel:+84764461287">
            <CallIcon />
          </a>
          <a className="ml-7 mr-7"
            href="https://github.com/nhalq"
            target="_blank"
            rel="noopener noreferrer">
            <GitHubIcon />
          </a>
          <a className="ml-7 mr-7"
            href="https://www.linkedin.com/in/nhalq/"
            target="blank"
            rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
        </div>
      </div>
    </div>
  )
}
