import WorkHistory from "@mui/icons-material/WorkHistory"

export interface BadgeProps {
  value: string
  img: string

  isWork?: boolean
}

export default function Badge(props: BadgeProps) {
  return (
    <button
      className="bg-opacity-70 h-32 w-full rounded-xl shadow-lg relative
        flex justify-center ml-auto mr-auto items-center transition-all
        hover:scale-125 hover:bg-gray-200"
      disabled
    >
      <img
        className="m-6 max-w-[4rem] max-h-[4rem]"
        src={props.img}
        alt={props.value}
      />
      {
        props.isWork && (
          <div className="absolute top-3 right-10 z-50">
            <span className="absolute flex items-center justify-center
              w-7 h-7 bg-yellow-200 rounded-full ring-8 ring-yellow-100"
            >
              <WorkHistory
                className="text-yellow-600"
                style={{ width: "1.25rem" }}
              />
            </span>
          </div>
        )
      }
    </button>
  )
}
