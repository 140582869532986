import { createContext } from "react";

export interface ModalCallbackProps {
  children: null | JSX.Element | JSX.Element[]
  onClose: () => void
}

export interface ModalCallback {
  (props: ModalCallbackProps): void;
}

export default createContext<ModalCallback>((props: ModalCallbackProps) => { })
