import LaunchIcon from '@mui/icons-material/Launch';

import { List } from "../Components"
import { useModal } from '../Components/Modal';

function DSA() {
  return (
    <div className="flex w-full h-full items-center justify-center relative">
      <div className="absolute w-full h-full bg-sl-whale opacity-10" />
      <div className="z-10">
        <List items={[
          "Rank 45th the 2020 ICPC Asia Can Tho Regional Contest",
          "TOP 1 CS Ranking & TOP 1 WeCode - Competitive Programming Contest of University Information Technology VNU",
          "Honorable Mention - Vietnam Olympiad in Informatics",
        ]} />
      </div>
    </div>
  )
}

export default function Skills() {
  const modal = useModal()

  function openModal() {
    modal({
      children: <DSA />,
      onClose: () => { }
    })
  }

  return (
    <List items={[
      <div className="flex items-center">
        <span>Data Structures & Algorithms</span>
        <button className="pl-0.5 text-yellow-500" onClick={openModal} >
          <LaunchIcon className="p-1" />
        </button>
      </div>
      ,
      "OOP & Design Pattern",
      "Computer Networking",
      "Operating Systems",
    ]} />
  )
}
