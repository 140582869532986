import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import ScienceIcon from '@mui/icons-material/Science';
import AddCircleIcon from '@mui/icons-material/AddCircle';


export function Icon({ where }: { where: string }) {
  if (where === "lab")
    return <ScienceIcon sx={{ width: "1.25rem" }} className="text-yellow-600" />
  if (where === "work")
    return <WorkIcon sx={{ width: "1rem" }} className="text-yellow-600" />
  if (where === "school")
    return <SchoolIcon sx={{ width: "1rem" }} className="text-yellow-600" />
  return <AddCircleIcon sx={{ width: "1rem" }} className="text-yellow-600" />
}

interface TagsProps {
  tags: string[]
}

export function Tags(props: TagsProps) {
  return (
    <p className="flex mb-3 text-base font-normal text-gray-500">
      {
        props.tags.map(tag => (
          <span
            key={tag}
            className="border rounded-tr-lg rounded-bl-lg px-2 mr-2
              border-yellow-200 bg-yellow-100 font-mono text-yellow-700"
          >
            {tag}
          </span>
        ))
      }
    </p>
  )
}

export interface RefProps {
  title: string
  link: string
}

interface RefsProps {
  refs?: RefProps[]
}

export function Refs(props: RefsProps) {
  return (
    <div className="mb-2">
      {props.refs?.map(ref =>
        <a
          key={ref.link}
          className="mr-3"
          href={ref.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            type="button"
            className="items-center text-sm text-yellow-600
            border border-yellow-400 px-2.5 py-0.5
            hover:bg-yellow-200"
          >
            {ref.title}
            <KeyboardDoubleArrowRightIcon sx={{ paddingLeft: '0.3rem' }} />
          </button>
        </a>
      )}
    </div>
  )
}
